import { useState, useEffect } from 'react';
import menu from '../images/hamburger.png'
import winwayLogo from '../images/winway-edu.png'
import { Link } from 'react-router-dom';
import closeIcon from '../images/close.png';

import winwayLogoSmall from '../images/w-logo.png'

export default function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navItems = [
        {
            url: '/',
            itemName: 'Home'
        },
        {
            url: '/about-us',
            itemName: 'About Us'
        },
        {
            url: '/courses',
            itemName: 'Courses'
        },
        {
            url: '/contact-us',
            itemName: 'Contact Us'
        },
        {
            url: '/blog',
            itemName: 'Blog'
        }
        
    ]
    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 45) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return <>
        <header>
            <div className={`w3-hide-small w3-hide-medium header-wrapper ${scrolled ? 'header-sticky' : ''}`} style={{ opacity: scrolled ? 1 : 0.8 }}>
                <div className="header-row">
                    <div className="header-logo">
                        <Link to="/"><img className='header-logo-img' src={winwayLogo} alt="winway-edu" /></Link>
                    </div>
                    <div className='header-navigation'>
                        <div className='header-navigation-container'>
                            <nav>
                                <ul>
                                    {navItems?.map((item, index) => (
                                        <Link className='navigation-item' to={item?.url}>
                                            <li key={`${item?.itemName}-${index}`}>{item?.itemName}</li>
                                        </Link>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w3-hide-large header-sticky-mobile'>
                <div className="header-row-mobile">
                    <div className="header-logo">
                        <Link to="/"><img className='header-logo-mobile-img' src={winwayLogoSmall} alt="winway-edu" /></Link>
                    </div>
                    <div>
                        <img onClick={toggleMenu} className='header-menu-icon' src={menu} alt="menu" />
                    </div>
                </div>

                <div className={`header-navigation-mobile ${isMenuOpen ? 'open' : ''}`}>
                    <div className='header-navigation-container-mobile'>
                        <div className='header-modal-close'>
                            <img onClick={() => setIsMenuOpen(false)} className='header-modal-close-icon' src={closeIcon} alt="close" />
                        </div>
                        <nav>
                            <ul>
                                {navItems?.map((item, index) => (
                                    <Link onClick={() => setIsMenuOpen(false)} className='navigation-item-mobile' to={item?.url} key={index}>
                                        <li>{item?.itemName}</li>
                                    </Link>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    </>
}