import { useEffect, useState } from 'react';
import contactTeam from '../images/contact-us.png'
export default function ContactUsForm({ otherComponent = false }) {
    const intialForm = {
        name: '',
        email: '',
        mobile: '',
        message: ''
    }
    const [form, setForm] = useState(intialForm);
    const [errors, setErrors] = useState({});

    const validateData = () => {
        let hasErrors = false;
        const err = { ...errors }
        if (!form.name) {
            hasErrors = true;
            err.name = "This field is required"
        }
        if (!form.email) {
            hasErrors = true;
            err.email = "This field is required"
        }
        if (!form.mobile) {
            hasErrors = true;
            err.mobile = "This field is required"
        }
        const phoneRegex = /^\d{10}$/;

        if (form.mobile && !phoneRegex.test(form.mobile)) {
            hasErrors = true;
            err.mobile = "Enter a valid phone number"
        }

        if (hasErrors) {
            setErrors(err)
            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validate = validateData()
        if (!validate) {
            return
        }
        setForm(intialForm)
        const formData = {
            name: form.name,
            email: form.email,
            phone: form.mobile,
            message: form.message
        }
        console.log('formData: ', formData);

        const scriptUrl = "https://script.google.com/macros/s/AKfycbw2oCQPYKf-o8CVJv_CXxOGBlVa9cgXW48BwBeRF7IyJd7T6MvnC2L0OIvqRIJ_ugAeNw/exec"
        try {
            const response = await fetch(scriptUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData).toString(),
            });
            const result = await response.json();
        } catch (error) {
            console.error("Error:", error);
        }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);
    }
    return (
        <div className='w3-row contact-form-container-main'>
            <div className='contact-form-row'>
                <div className={`w3-col l6 m12 s12 ${otherComponent ? "" : "contact-form-padding"} contact-form-container`} >
                    <h3 className='home-tags'>We're Here to Help You!</h3>
                    <h2>Get in Touch with Us</h2>
                    <div>
                        <div>
                            <input
                                type="text"
                                name="name"
                                required
                                placeholder='Name'
                                value={form.name}
                                onChange={handleChange} />
                            {errors.name && <p className='error'>{errors.name}</p>}
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                required
                                placeholder='Email'
                                value={form.email}
                                onChange={handleChange} />
                            {errors.email && <p className='error'>{errors.email}</p>}
                        </div>
                        <div>
                            <input
                                type="tel"
                                name="mobile"
                                value={form.mobile}
                                placeholder='Phone Number'
                                pattern="[0-9]{10}"
                                required
                                onChange={handleChange} />
                            {errors.mobile && <p className='error'>{errors.mobile}</p>}
                        </div>
                        <textarea
                            type="message"
                            name="message"
                            required
                            placeholder='Message'
                            value={form.message}
                            onChange={handleChange} rows="4"
                        />
                        {errors.message && <p className='error'>{errors.message}</p>}
                        <div className='contact-form-button-container'>
                            <button className='contact-form-submit' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className='w3-col l6 m12 s12 contact-form-image-container'>
                    <img className='contact-form-image' src={contactTeam} alt="contact-us" />
                </div>
            </div>
        </div>
    )
}