import { Link } from "react-router-dom";
import fb from '../images/svg/fb.svg';
import insta from '../images/svg/ig.svg';
import email from '../images/mail.png'
import call from '../images/phone-call.png'

export default function Footer() {
    const footerItems = [
        // {
        //     header: 'Courses',
        //     sectionItems: [
        //         {
        //             title: 'Home',
        //             url: '/'
        //         },
        //         {
        //             title: 'About Us',
        //             url: '/about-us'
        //         },
        //         {
        //             title: 'Contact Us',
        //             url: '/contact-us'
        //         },
        //     ]
        // },
        {
            header: 'Services',
            sectionItems: [
                {
                    title: 'Counselling',
                    url: '/contact-us'
                },
                {
                    title: 'Admission',
                    url: '/contact-us'
                },
                {
                    title: 'Mentoring Program',
                    url: '/contact-us'
                },
            ]
        },
        // {
        //     header: 'Web Development',
        //     sectionItems: [
        //         {
        //             title: 'Web Design Company India',
        //             url: '/web-development'
        //         },
        //         {
        //             title: 'Web Design Company Kerala',
        //             url: '/web-development'
        //         },
        //         {
        //             title: 'Web Design Company Kannur',
        //             url: '/web-development'
        //         },
        //     ]
        // },
        // {
        //     header: 'Digital Marketing',
        //     sectionItems: [
        //         {
        //             title: 'Digital Marketing',
        //             url: '/web-development'
        //         },
        //         {
        //             title: 'SEO Company Kerala',
        //             url: '/web-development'
        //         },
        //         {
        //             title: 'Digital Marketing',
        //             url: '/web-development'
        //         },footer-items-header
        //     ]
        // }
    ]
    return <footer className="footer">
        <div className="footer-main">
            <div className="footer-container">
                <div className="footer-items">
                    <div className="footer-row">
                        <div className="footer-items-header-contact footer-description">
                            <h6>Winway Edu</h6>
                            <p>Winway Edu Is A Leading Education Consultants In Kannur As Well As Kerala, Partnered With More Than 100 World Ranking Universities.</p>
                        </div>
                        <div className="footer-items-header-contact">
                            <h6>Address</h6>
                            <p>Winway Edu Solutions <br /> Second floor <br /> S.S Mall, Mattannur</p>
                            <div className='home-featured-contact-container' style={{marginTop: '15px'}}>
                                        <div className='home-feature-contact-call'>
                                            <img className='home-feature-contact-icon' src={call} alt="call" />
                                            <a href="tel:+918281570962"><p>8281570962</p></a>
                                        </div>
                                        <div className='home-feature-contact-email'>
                                            <img className='home-feature-contact-icon' src={email} alt="email" />
                                            <a href="mailto:winwayedusolutions@gmail.com"> <p>winwayedusolutions@gmail.com</p></a>
                                        </div>
                                    </div>
                        </div>
                        {footerItems?.map((item, index) => (
                            <div className="footer-column-container">
                                <div className="footer-column-items">
                                    <div className="footer-items-header">
                                        <h6>{item?.header}</h6>
                                    </div>
                                    <div className="footer-cta">
                                        {item?.sectionItems?.map((value, idx) => (
                                            <Link key={idx} className="footer-cta-items" to={value?.url}>
                                                <p className="hover-underline-animation">{value?.title}</p>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <br />
            <div className="footer-hr">
                <hr />
            </div>
            <div className="footer-copyright-container">
                <div className="footer-social-media">
                    <div className="footer-social-icons">
                        <Link target="_blank"  to="https://www.facebook.com/people/Winway-Edusolutions/61559345798590/"><img className="footer-social-img" src={fb} alt="footer-social-fb" /></Link>
                        <Link target="_blank"  to="https://www.instagram.com/winway_edusolutions"><img className="footer-social-img" src={insta} alt="footer-social-insta" /></Link>
                    </div>
                </div>
                <div className="footer-copyright-text">
                    <p>© 2024. All rights reserved Made with ❤️️ at Devbytez </p>
                </div>
                <div className="footer-copyright-cta">
                    <ul>
                        <li><Link className="cta hover-underline-animation" to="/privacy-policy">Privacy Policy</Link></li>
                        <li className="w3-hide-large">|</li>
                        <li><Link className="cta hover-underline-animation" to="/privacy-policy">Terms &amp; Conditions</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
}