import { Link } from 'react-router-dom'
import notFound from './images/page-not-found.jpg'
import { useEffect } from 'react'
export default function NotFound() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="page-not-found">
            <img className='page-not-found-img' src={notFound} alt="page-not-found" />
            <div className='page-not-found-text'>
                <Link className='page-not-found-button' to='/'><>Homepage</></Link>
            </div>
        </div>
    )
}