import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './Home/HomePage';
import NotFound from './NotFound';
import Navbar from './Header/Navbar';
import Footer from './Footer/Footer';
import AboutUs from './About/AboutUs';
import ContactUs from './Contact/ContactUs';
import Courses from './Courses/Courses';
import WhatsAppButton from './Contact/WhatsAppButton';
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <div style={{ minHeight: 'calc(100vh - 60px)' }}>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/courses" element={<Courses />} />
            
            <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
        <WhatsAppButton />
      <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
