import banner from "../images/aboutus.jpg";
import aboutUsImg from '../images/about-team.jpg'
import { useEffect } from "react";
export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="about-container">
            <div className="about">
                <div className="about-main">
                    <div className="about-hero-container">
                        <div className="w3-row about-hero-row">
                            <div className="w3-col l6 m12 s12 about-hero-image-container">
                                <img className="about-hero-image" src={banner} alt="about-us" />
                            </div>
                            <div className="w3-col l6 s12 m12 about-hero-text">
                                <h1>About Us</h1>
                                <p>Winway Edu is your trusted education consultant in Kerala, based in Kannur. We help students navigate the admission process for top universities in India, offering expert advice on course selection and career planning.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-content">
                    <div className="w3-row">
                        <div className="w3-col l6 m12 s12">
                            <div className="about-content-tag">
                                Our Story
                            </div>
                            <div className="about-content-text">
                                <div className="about-content-header">
                                    <h2>Best Education Consultants in<span> Kannur</span></h2>
                                </div>
                                <div className="about-content-para">
                                    <p>At Winway Edu, we are dedicated to helping students discover their path to success. Based in Kannur, Kerala, we guide students in navigating their educational journeys and making informed decisions about their futures. Our personalized approach matches students with experienced mentors and provides expert counseling focused on higher education opportunities.

                                        We assess each student's unique strengths and aspirations, ensuring they are well-prepared for the admission process to top universities across India. With a strong emphasis on employability, we empower students to pursue their dreams and unlock their full potential. Join us at Winway Edu, where we turn aspirations into achievements!</p>
                                    {/* <div className="about-content-cta-container">
                                        <button className="about-content-cta">Know more</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="w3-col l6 m12 s12 about-contnet-image-container">
                            <img className="about-us-content-image" src={aboutUsImg} alt="about-us-content" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}