import { useEffect } from 'react';
import ContactUsForm from './ContactUsForm';
export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
   
    return (
        <div className='contact-us-container'>
            <div className='contact-us-hero'>

            </div>
            <ContactUsForm />
        </div>
    )
}