import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import aboutUsImg from '../images/office-work.png';
import bannerClipart from '../images/banner-clipart.png';
import directions from '../images/guide-path.png'

import featuredBanner from '../images/featured-bg.jpg'

import shape1 from '../images/1.png'
import shape2 from '../images/7.png'
import shape3 from '../images/4.png'

import mortarboard from '../images/mortarboard.png'
import statistics from '../images/bar-chart.png'
import creativity from '../images/creativity.png'


import rightArrow from '../images/right-arrow.png'

import email from '../images/mail.png'
import call from '../images/phone-call.png'

import Modal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';

import modalBg from '../images/learn.jpg';
import closeIcon from '../images/close.png';
import verified from '../images/checked.png';

import { faqData } from '../Constants/utils'
import ContactUsForm from '../Contact/ContactUsForm';




export default function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const intialForm = {
        name: '',
        email: '',
        mobile: '',
    }
    const [form, setForm] = useState(intialForm);
    const [errors, setErrors] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false)
        setErrors({})
        setForm(intialForm)
        setModalSuccess(false)
    };
    const cardItems = [
        {
            title: 'Undergraduate',
            content: 'Winway Edu Solutions helps students secure admissions to top Undergraduate Programs. Our expert team offers personalized guidance, covering application requirements and deadlines, to ensure a smooth transition to college.',
            image: mortarboard
        },
        {
            title: 'Postgraduate',
            content: 'For Postgraduate Programs, Winway Edu Solutions provides essential support throughout the admission process. We help you choose the right program, prepare your application, and navigate deadlines, ensuring your success in advancing your education.',
            image: statistics
        },
        {
            title: 'Diploma',
            content: 'Winway Edu Solutions offers dedicated assistance for Diploma Programs admissions. We help you find the right institution and program that fits your career goals, providing guidance on application procedures and deadlines for a seamless experience.',
            image: creativity
        }
    ]
    const stepsCardItems = [
        {
            title: 'Counselling',
            content: 'Winway Edu Solutions offers expert career counseling to help students in Kerala choose the best courses and colleges in Karnataka, Tamil Nadu, and beyond.',
            image: mortarboard
        },
        {
            title: 'Admission',
            content: 'We provide complete admission support, helping students secure spots in top institutions across India, including Kerala, Karnataka, and Tamil Nadu.',
            image: statistics
        },
        {
            title: 'Test Preparation',
            content: 'Our tailored test preparation ensures students excel in entrance exams, boosting their chances of admission to top colleges.',
            image: statistics
        },
        {
            title: 'Mentoring Program',
            content: 'Winway offers ongoing mentoring to guide students through their educational journey, ensuring they reach their career goals.',
            image: creativity
        }
    ]



    const aboutUs = ["Seamless Admission with Expert Guidance", "Tailored Services for Your Academic Needs", "Partnered with Top Institutions Across India", "Trusted Education Consultancy in Kerala"]
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const validateData = () => {
        let hasErrors = false;
        const err = { ...errors }
        if (!form.name) {
            hasErrors = true;
            err.name = "This field is required"
        }
        if (!form.email) {
            hasErrors = true;
            err.email = "This field is required"
        }
        if (!form.mobile) {
            hasErrors = true;
            err.mobile = "This field is required"
        }
        const phoneRegex = /^\d{10}$/;

        if (form.mobile && !phoneRegex.test(form.mobile)) {
            hasErrors = true;
            err.mobile = "Enter a valid phone number"
        }

        if (hasErrors) {
            setErrors(err)
            return false
        }

        return true
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validate = validateData()
        if (!validate) {
            return
        }
        setForm(intialForm)
        setModalSuccess(true)
        const formData = {
            name: form.name,
            email: form.email,
            phone: form.mobile
        }
        console.log('formData: ', formData);

        const scriptUrl = "https://script.google.com/macros/s/AKfycbw2oCQPYKf-o8CVJv_CXxOGBlVa9cgXW48BwBeRF7IyJd7T6MvnC2L0OIvqRIJ_ugAeNw/exec"
        try {
            const response = await fetch(scriptUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData).toString(),
            });
            const result = await response.json();
        } catch (error) {
            console.error("Error:", error);
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);
    }
    return (
        <div className='homepage-container'>
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-container">
                    <div className='modal-close'>
                        <img className='modal-close-img' onClick={handleCloseModal} src={closeIcon} alt="close" />
                    </div>
                    <div className='w3-row modal-element-container'>
                        <div className='w3-col l6 m12 s12 modal-image-container w3-hide-small w3-hide-medium'>
                            <img className='modal-image' src={modalBg} alt="modal-bg" />
                        </div>
                        {!modalSuccess && <div className='w3-col l6 m12 s12 modal-form-container'>
                            <h3>Enquire Now</h3>
                            <h2>To Get a Free Consultation and
                                Step by Step Guidance</h2>
                            <div>
                                <div>
                                    <input
                                        type="text"
                                        name="name"
                                        required
                                        placeholder='Name'
                                        value={form.name}
                                        onChange={handleChange} />
                                    {errors.name && <p className='error'>{errors.name}</p>}
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        placeholder='Email'
                                        value={form.email}
                                        onChange={handleChange} />
                                    {errors.email && <p className='error'>{errors.email}</p>}
                                </div>
                                <div>
                                    <input
                                        type="tel"
                                        name="mobile"
                                        value={form.mobile}
                                        placeholder='Phone Number'
                                        pattern="[0-9]{10}"
                                        required
                                        onChange={handleChange} />
                                    {errors.mobile && <p className='error'>{errors.mobile}</p>}
                                </div>
                                <div className='modal-form-button-container'>
                                    <button className='modal-form-submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>}

                        {modalSuccess && <div className='modal-verified-container'>
                            <img className='modal-verified' src={verified} alt="verified" />
                            <div className='modal-verified-text'>
                                <h3>One of our executives will contact you shortly.
                                    Thank you for your time!</h3>
                            </div>
                        </div>}
                    </div>

                </Box>
            </Modal>
            <section id="home-banner" className='home-banner'>
                <div className='homepage-banner'>
                    <div className='w3-row'>
                        <div className='w3-col l7 m7 s12 home-banner-container'>
                            <div className='home-banner-text'>
                                <h1>A plan for <br /> <span>Every Ambition</span></h1>
                                <p>Winway Edu Solutions provides personalized admission plans to help you achieve your academic goals.</p>
                            </div>
                            <div className='w3-row home-banner-button-container'>
                                <div className='w3-col l6 m12 s12'>
                                    <button onClick={handleShowModal} className='banner-talk-to-expert-button'>Talk to an Expert</button>
                                </div>
                            </div>
                        </div>
                        <div className='w3-col l5 m5 s6'>
                            <p style={{ visibility: 'hidden' }}>thejas</p>
                        </div>
                    </div>
                    <div className='shape shape-1'>
                        <img src={shape1} alt="shape-1" />
                    </div>
                    <div className='shape shape-3'>
                        <img src={shape3} alt="shape-3" />
                    </div>
                    <div className='shape shape-4'>
                        <img src={shape2} alt="shape-4" />
                    </div>
                </div>
            </section>
            <section id="home-cards">
                <div className='home-card-container'>
                    <div className='home-steps-container'>
                        <div className='home-steps-text'>
                            <p className='home-tags'>Featured courses</p>
                            <h2>Explore Featured <br /> <span>Courses</span></h2>
                        </div>
                    </div>
                    <div className='w3-row home-card-row'>
                        <div className='home-card-item-container'>
                            {cardItems?.map((item, idx) => (
                                <div className='card-container'>
                                    <div className='w3-col l4 m4 s12 home-card-item'>
                                        <div className='card-icon-container'>
                                            <img src={item?.image} className='card-icon' alt={`card-icon-${idx}`} />
                                        </div>
                                        <h3>{item?.title}</h3>
                                        <p>{item?.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section id="home-about" className='home-about'>
                <div className='w3-row home-about-container'>
                    <div className='w3-col l6 m12 s12'>
                        <div className='home-about-text'>
                            <div className='home-about-tag-container'>
                                <p className='home-about-us-tag home-tags'>About Us</p>
                            </div>
                            <div className='home-about-tag-container'>

                                <h2>We Guide Your <br /> <span>Path to Success</span> </h2>
                            </div>
                            <p>At Winway Edu Solutions, we help students from Kerala secure admissions to top universities and colleges across India. With expert guidance and free counseling, we make the admission process simple and stress-free. Our goal is to ensure every student gets the best opportunities for a bright academic future, making us the trusted educational consultancy in Kerala.</p>
                            <ul>
                                {aboutUs?.map(item => (
                                    <div className='home-about-list'>
                                        <img src={rightArrow} className='about-arrow' alt='right-arrow' />
                                        <li className='home-about-list-text'> {item}</li>
                                    </div>
                                ))}
                            </ul>
                            <div className='home-about-button-container'>
                                <button onClick={() => navigate('/about-us')} className='home-about-button'>Discover More</button>
                            </div>
                        </div>
                    </div>
                    <div className='w3-col l6 m12 s12'>
                        <div className='home-about-image-container'>
                            <img className='home-about-image' src={aboutUsImg} alt="about us" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="processing-steps" className='home-steps'>
                <div className='home-steps-container'>
                    <div className='home-steps-text'>
                        <div className='home-steps-tag'>
                            <p className='home-tags'>Services</p>

                        </div>
                        <h2>Take Some Easy Steps For  <span>Processing</span></h2>
                        <h4>At Winway Edu Solutions, we make the journey from career selection to college admission seamless and stress-free. Here are the key services we provide to help you secure your future:</h4>
                    </div>
                </div>
                <div className='home-steps-cards-container'>
                    <div className='w3-row home-steps-card-row'>
                        <div className='home-steps-card-item-container'>
                            {stepsCardItems?.map((item, idx) => (
                                <div className='steps-card-container'>
                                    <div className='w3-col l3 m3 s12 home-steps-card-item'>
                                        <div className='steps-card-icon-container'>
                                            <img src={item?.image} className='steps-card-icon' alt={`steps-card-icon-${idx}`} />
                                        </div>
                                        <h3>{item?.title}</h3>
                                        <p>{item?.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-featured-courses" className='home-featured-courses'>
                <div className='home-featured-courses-container'>
                    <div className='home-featured-courses-text-container'>
                        <div className='w3-row home-featured-container'>
                            <div className='w3-col l6 m12 s12'>
                                <div className='home-featured-courses-image-container'>
                                    <img className='home-featured-courses-image' src={directions} alt="featured courses" />
                                </div>
                            </div>
                            <div className='w3-col l6 m12 s12'>
                                <div className='home-featured-courses-text'>
                                    <div className='home-featured-tag-container'>
                                        <p className='home-featured-courses-tag home-tags'>Schedule Now</p>
                                    </div>
                                    <div className='home-featured-tag-container'>
                                        <h2>Chart Your <span>Journey!</span> </h2>
                                    </div>
                                    <p>At Winway Edu Solutions, our expert consultants are ready to guide you through every step of your education journey. We assist with course selection and admissions to top universities in Kerala, Karnataka, Tamil Nadu, and beyond. From choosing the right course to securing your spot, we provide the support you need for a successful future.</p>
                                    <div className='w3-row home-featured-courses-button-container'>
                                        <div className='w3-col l6 m12 s12'>
                                            <button onClick={handleShowModal} className='home-featured-courses-schedule-appointment-button'>Schedule an Appointment</button>
                                        </div>
                                        <div className='w3-col l6 m12 s12'>
                                            <button onClick={() => navigate('/about-us')} className='home-featured-courses-learn-more-button'>Learn More</button>
                                        </div>
                                    </div>
                                    <div className='home-featured-contact-container'>
                                        <div className='home-feature-contact-call'>
                                            <img className='home-feature-contact-icon' src={call} alt="call" />
                                            <a href="tel:+918281570962"><p>8281570962</p></a>
                                        </div>
                                        <div className='home-feature-contact-email'>
                                            <img className='home-feature-contact-icon' src={email} alt="email" />
                                            <a href="mailto:winwayedusolutions@gmail.com"> <p>winwayedusolutions@gmail.com</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="home-faq" className='home-faq'>
                <div className="home-faq-container">
                    <div className='home-faq-tag-container'>
                        <p className='home-faq-courses-tag home-tags'>Have a question?</p>
                    </div>
                    <div className='home-faq-tag-container'>
                        <h2>Read our <span>FAQ</span> </h2>
                    </div>
                    <ul className="home-faq-list">
                        {faqData.map((faq, index) => (
                            <li key={index} className="home-faq-item">
                                <div
                                    className={`home-faq-question ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => toggleFaq(index)}
                                >
                                    <p>{activeIndex === index ? '-' : '+'}</p> {faq.question}
                                </div>
                                <div
                                    className={`home-faq-answer ${activeIndex === index ? 'open' : ''}`}
                                    style={{ maxHeight: activeIndex === index ? '450px' : '0' }}  // Toggle height
                                >
                                    <p>{faq.answer}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            <section id="home-contact" className='home-contact-main'>
                <div className='home-contact'>
                    <div className="home-contact-container">
                        <ContactUsForm otherComponent={true} />
                    </div>
                </div>
            </section>
            
        </div>
    )
}