import React from 'react';
import whatsappIcon from '../images/whatsapp-icon.png'

const WhatsAppButton = () => {
  const phoneNumber = '+918281570962';
  const message = 'Hello, I would like to know more about your services.';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div>
      <div className="whatsapp-button" onClick={handleClick}>
      <img className="whatsapp-button-img" src={whatsappIcon} alt="whatsapp-icon" />
    </div>
    </div>
  );
};

export default WhatsAppButton;
