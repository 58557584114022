export const faqData = [
        {
            "question": "Does Winway Edu take fees?",
            "answer": "No, Winway Edu does not charge any fees for our counseling and admission services. Our goal is to provide free, high-quality guidance to students in Kerala, ensuring they get the best opportunities for admissions in Karnataka, Tamil Nadu, and other states across India. We believe in transparent services and focus entirely on helping students find the right courses and institutions."
        },
        {
            "question": "What does Winway Edu do?",
            "answer": "Winway Edu is the leading educational consultancy in Kerala, providing expert guidance for students seeking admissions in Karnataka, Tamil Nadu, and other states. We assist with course and institution selection, handling the entire admission process to help students secure seats in top colleges. Whether it's engineering, medical, arts, or other professional courses, we ensure students make informed decisions for their higher education."
        },
        {
            "question": "How old is Winway Edu?",
            "answer": "Winway Edu started in 2024, and although we are relatively new, our team consists of experienced educational consultants who have been helping students from Kerala with admissions to top institutions across India. We have quickly built a reputation for being the best educational consultancy in Kannur and the Malabar region, offering valuable insights for students interested in studying in states like Karnataka, Tamil Nadu, and Kerala."
        },
        {
            "question": "Where is Winway Edu located?",
            "answer": "Winway Edu is based in Kerala, primarily serving students from the Malabar region, including Kannur. We provide comprehensive admission assistance for students who wish to pursue their higher education in states like Karnataka, Tamil Nadu, and other regions of India."
        },
        {
            "question": "What services does Winway Edu offer?",
            "answer": "At Winway Edu, we provide expert career counseling, course selection, and admission guidance for top colleges in Karnataka, Tamil Nadu, Kerala, and beyond. Whether it's engineering, medical, or arts, we ensure a smooth admission process with ongoing support."
        }
    ]
