
import { Link } from 'react-router-dom';
import courseBanner from '../images/courses.png';
import { useEffect } from 'react';

export default function Courses() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const courseList = [
        {
            title: 'Engineering',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Medical',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Ayurvedha',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Dental',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Allied Health',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Pharmacy',
            image: courseBanner,
            cta: '#'
        },
        {
            title: 'Homeopathy',
            image: courseBanner,
            cta: '#'
        },
    ]
    return (
        <div className="courses-container">
            <div className="courses">
                <div className="courses-main">
                    <div className="courses-hero-container">
                        <div className="w3-row courses-hero-row">
                            <div className="w3-col l6 s12 m12 courses-hero-image-container">
                                <img src={courseBanner} className='courses-hero-image' alt="courses" />
                            </div>
                            <div className="w3-col l6 s12 m12 courses-hero-text">
                                <h1>Courses</h1>
                                <p>At Winway Edu Solutions, we offer expert guidance to help you choose the right undergraduate, postgraduate, or diploma courses. With personalized support, we ensure you find the best programs at top institutions across India, setting you on the path to success.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='courses-list'>
                    <div className='courses-list-container'>
                        <div className='w3-row courses-card-row'>
                            {courseList?.map(item => (
                                <div className='courses-card-container'>
                                    <div className='w3-col l3 m3 s12 courses-card'>
                                        <div className="courses-card-main">
                                            <div className='courses-card-bg'>
                                                <img className='courses-card-image' src={item?.image} alt={`Best Educational Consultants in Kerala | ${item.title}`} />
                                                <h2>{item?.title}</h2>
                                                <div className='courses-card-cta'>
                                                    <Link to={item?.cta}><button>View Courses</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}